<template src="./tagsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'tags-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'tagsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Tag',
                tagName: '',
                tagNotes: '',
                isActive: true,
            }
        },
        computed: {
            mode() {
                return this.modalData.mode;
            },
        },
        methods: {
            getDto() {
                let incomingDto = this.modalData.tag;

                Object.assign(incomingDto, 
                    {
                        TagName: this.tagName,
                        TagNotes: this.tagNotes,
                        IsActive: this.isActive,
                    }
                );

                return incomingDto;
            },
            saveTag() {
                const dto = this.getDto();

                let endpoint = this.mode === 'new' ? '/Tags/Create' : '/Tags/Update';

                return DM.http({
                    method: 'POST',
                    url: endpoint,
                    data: dto,
                }).then((response) => {
                    return response;
                });
            },
            btnSaveOnClick() {
                this.saveTag().then((response) => {
                    this.btnCancelOnClick();
                });
            },
            btnSaveAndAddMoreOnClick() {
                this.saveTag()
                    .then(response => {
                        this.modalData.callback(response);
                        this.tagName = '';
                        this.tagNotes = '';

                        setTimeout(() => {
                            this.isActive = true;
                        },300);
                    });
            },
            btnCancelOnClick() {
                if (this.modalData.callback) {
                    this.modalData.callback();
                }

                this.modalRef.hide();
            },
        },
        created() {
            if (this.mode === 'edit') {
                this.lblTitle = 'Edit Tag: ' + this.modalData.tag.TagName;

                this.tagName = this.modalData.tag.TagName;
                this.tagNotes = this.modalData.tag.TagNotes;
                this.isActive = this.modalData.tag.IsActive;
            }
        },
    }
</script>
