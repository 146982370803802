var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dm-page-container dm-flex-column dm-padding-md dm-height-100"},[_c('div',{staticClass:"dm-flex-row dm-justify-content-space-between dm-align-items-center dm-margin-bottom-md"},[_c('h3',[_vm._v(" Program/Project Tags ")]),_c('ejs-button',{attrs:{"is-primary":"true"},nativeOn:{"click":function($event){return _vm.addTagOnClick.apply(null, arguments)}}},[_c('span',{staticClass:"fa fa-plus-square"}),_vm._v(" Add Program/Project ")])],1),_c('div',{staticClass:"dm-position-relative dm-flex-1 dm-overflow-hidden"},[_c('dm-table',{ref:"grdTag",attrs:{"table":{
                grdTableItems: _vm.grdTagItems,
                grdTableRowOnClick: _vm.grdTagRowOnClick,
                grdTableActionOnClick: _vm.grdTagActionOnClick,
                grdTableSortBy: _vm.grdTagSortBy,
                grdTableClass: _vm.grdTagClass,
                grdTableFields: _vm.grdTagFields
            },"items":_vm.grdTagItems}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }