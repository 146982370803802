<template src="./faqsPage.html"></template>

<script>
    export default {
        name: 'faqs-page',
        data: function () {
            return {
                faqs: null,
                currentCropYear: null
            }
        },
        methods: {
            getFaqs() {
                DM.http({
                    method: 'GET',
                    url: '/Faqs/GetAll'
                }).then(response => {
                    this.faqs = response;
                });
            },
            getCurrentCropYear() {
                DM.http({
                    method: 'GET',
                    url: '/Crops/GetCurrentCropYear'
                }).then(response => {
                    this.currentCropYear = response.CropYear;
                });
            }
        },
        created() {
            this.getFaqs();
            this.getCurrentCropYear();
        },
        mounted() {
            
        }
    }
</script>
