<template src="./supportModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'support-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'supportModal' + DM.getRandomGuid(),
                lblTitle: 'Support',
            }
        },
        methods: {
            btnCancelOnClick() {
                this.modalRef.hide();
            },
        },
        created() {

        },
        mounted() {

        }
    }
</script>
