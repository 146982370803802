<template src="./verificationsEditModal.html"></template>

<script>
    import Vue from 'vue'
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import verificationsData from '../verificationsPage/verificationsData'

    export default {
        name: 'verifications-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'verificationsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add Verification',
                progressOptions: [],
                progress: '',
                notes: '',
                files: [],
                documentsReceived: false,
                verificationPassed: false,
                grdFilesFields: [],
                grdFilesItems: [],
                grdFilesSortBy: {
                    columns: [
                        {field: 'FileName', direction: 'Ascending'},
                    ]
                },
                grdFilesClass: '',
                mode: '',
            }
        },
        watch: {
            progress(newVal) {
                if (newVal !== 'Completed') {
                    this.verificationPassed = false;
                }
            }
        },
        methods: {
            saveVerification() {
                let url = '/Verifications/Update';
                let data = DM.copy(this.modalData.verification);

                Object.assign(data, {
                    FieldVerificationID: this.modalData.verification.FieldVerificationID,
                    VerificationProgress: this.progress,
                    VerificationNotes: this.notes,
                    VerificationPassed: this.verificationPassed,
                    DocumentsReceived: this.documentsReceived,
                    // HasCompletedVerification”: false ******
                });

                return DM.http({
                    method: 'POST',
                    url: url,
                    data: data
                }).then(response => {
                    return data;
                });
            },
            btnSaveOnClick() {
                this.saveVerification()
                    .then(response => {
                        this.modalData.callback(response);
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getProgressOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Verifications/GetVerificationProgressOptions',
                }).then(response => {
                    this.progressOptions = response.map(o => { return {value: o, text: o}; });
                    this.progress = this.modalData.verification.VerificationProgress;
                });
            }
        },
        created() {
            this.getProgressOptions();

            let verification = this.modalData.verification;
            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit') {
                this.lblTitle = 'Edit Verification: ' + this.modalData.verification.FieldName;
                // if(this.modalData.verifier) {
                //     this.lblTitle += ' - ' + this.modalData.verifier;
                // }
            }

            this.progress = verification.VerificationProgress;
            this.notes = verification.VerificationNotes;
            this.verificationPassed = verification.VerificationPassed;
            this.documentsReceived = verification.DocumentsReceived;
        },
    }
</script>
