<template src="./signInPage.html"></template>

<script>
    export default {
        name: 'sign-in-page',
        data: function () {
            return {
                emailAddress: '',
                password: '',
                showForgotPassword: false
            }
        },
        methods: {
            signIn() {
                DM.http({
                    method: "POST",
                    url: "/Users/SignIn",
                    data: {
                        EmailAddress: this.emailAddress,
                        Password: this.password
                    }
                }).then(response => {
                    DM.localStorageSet(app.localStorageAuthTokenName, response.AuthToken);
                    DM.authToken = response.AuthToken;
                    eventBus.$emit('setSignedInUserAndLoadStartPage', response);
                });
            },
            sendNewPassword() {
                DM.http({
                    method: "POST",
                    url: "/Users/SendTempPasswordEmail",
                    data: {
                        EmailAddress: this.emailAddress,
                    }
                }).then(response => {
                    this.showForgotPassword = false;
                });
            }
        },
        created() {

        }
    }
</script>
