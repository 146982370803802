<template src="./reportsPage.html"></template>

<script>
    export default {
        name: 'reports-page',
        data: function () {
            return {
                cropYear: null,
                cropYearOptions: [],
                path:  {
                    saveUrl: window.apiRoot + '/Exports/ConvertPcmToGeneralReport',
                },
                success: false
            }
        },
        methods: {
            exportAllDataOnClick() {
                this.exportCsv('/Exports/GeneralReport');
            },
            exportSummarizedDataOnClick() {
                let url = '';
                if(app.signedInUser.UserType === 'Admin') {
                    url = '/Exports/AdminSummarizedReport';
                } else {
                    url = '/Exports/SummarizedReport';
                }

                this.exportCsv(url);
            },
            getCropYearOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Crops/GetAllYears',
                }).then(response => {
                    this.cropYearOptions = response.CropYears.map(y => {
                        return {value: y, text: y}
                    });
                });
            },
            exportCsv(url) {
                DM.addRequest();
                axios({
                    method: 'GET',
                    url: window.apiRoot + url,
                    params: {
                        CropYear: this.cropYear
                    },
                    headers: {Authorization: 'Bearer ' + DM.authToken},
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let content = response.headers['content-disposition'];
                        let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();
                        this.downloadAjaxResponseAsFile(response, filename)
                    })
                    .catch((response) => {
                        try {
                            response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.response.data)));
                        } catch (err) {

                        }

                        let errorString = response.data.Error ? response.data.Error : "An unexpected error has occurred on the server.";
                        DM.alertShow(errorString, 'Error');
                    })
                    .finally(() => {
                        DM.removeRequest();
                    });
            },
            downloadAjaxResponseAsFile(response, fileName) {
                let blob = new Blob([response.data], {type: 'text/csv'});
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                document.body.removeChild(a);
            },
            uploadSuccess() {
                this.success = true;
            },
            uploadBefore(event, file, name) {
                let request = event.currentRequest;

                request.setRequestHeader('Authorization', 'Bearer ' + DM.authToken);
                request.method = file;

                request.onreadystatechange = function(e) {
                    if(request.readyState === XMLHttpRequest.DONE) {
                        let status = request.status;
                        if (status === 0 || (status >= 200 && status < 400)) {
                            let csvData = request.response;
                            console.log(request.response);
                            let content = request.getResponseHeader('content-disposition');
                            let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();

                            let blob = new Blob([csvData], {type: 'text/csv'});
                            let objectUrl = URL.createObjectURL(blob);
                            let a = document.createElement("a");
                            document.body.appendChild(a);
                            a.href = objectUrl;
                            a.download = filename;
                            a.click();
                            document.body.removeChild(a);
                        } else {
                            DM.alertShow(JSON.parse(e.currentTarget.response).Error, 'Error');
                        }
                    }
                }
            },
        },
        created() {
            this.getCropYearOptions();
        },
        mounted() {

        },
        computed: {
            userType() {
                return app.signedInUser.UserType;
            }
        }
    }
</script>
