<template src="./outcomesPage.html"></template>

<script>
    export default {
        name: 'outcomes-page',
        data: function () {
            return {
                reductionsCropYear: null,
                reductionsCounty: null,
                reductionsTags: [],
                reductionsCountyOptions: [],

                adoptionsCropYear: null,
                adoptionsCounty: null,
                adoptionsTags: [],
                adoptionsCountyOptions: [],

                cropYearOptions: [],
                tagOptions: [ { text: 'Untagged', value: 0 } ],

                reductions: [],
                adoptions: [],
                userIsAdmin: false
            }
        },
        methods: {
            getCropYearOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Crops/GetAllYears',
                }).then(response => {
                    this.cropYearOptions = response.CropYears.map(y => {
                        return {value: y, text: y}
                    });
                });
            },
            getAdoptionsCountyOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Outcomes/GetCounties',
                    params: {
                        OutcomeType: 'Adoptions',
                        IncludeAggregates: true
                    }
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = { text: type.DisplayText, value: type.CountyID };
                        this.adoptionsCountyOptions.push(dataObject);
                    });
                });
            },
            getReductionsCountyOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Outcomes/GetCounties',
                    params: {
                        OutcomeType: 'Reductions',
                        IncludeAggregates: true
                    }
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = { text: type.DisplayText, value: type.CountyID };
                        this.reductionsCountyOptions.push(dataObject);
                    });
                });
            },
            getTagOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetAll'
                }).then(response => {
                    response.forEach(tag => {
                        let formattedOption = { text: tag.TagName, value: tag.TagID };
                        this.tagOptions.push(formattedOption);
                    });
                });
            },
            getAdoptionsData() {
                DM.http({
                    method: 'POST',
                    url: '/Outcomes/GetAllAdoptions',
                    data: {
                        cropYear: this.adoptionsCropYear || -1,
                        countyID: this.adoptionsCounty,
                        tagIDs: this.adoptionsTags
                    }
                })
                    .then(response => {
                        this.adoptions = response;
                    });
            },
            getReductionsData() {
                DM.http({
                    method: 'POST',
                    url: '/Outcomes/GetAllReductions',
                    data: {
                        cropYear: this.reductionsCropYear || -1,
                        countyID: this.reductionsCounty,
                        tagIDs: this.reductionsTags
                    }
                })
                    .then(response => {
                        this.reductions = response;
                    });
            },
            setAdoptionDefaultCountyOption() {
                let countyIDs = app.signedInUser.CountyIDs;
                const defaultCountyOption = -3;
                if (app.signedInUser.UserType === 'Admin' && countyIDs.length === 0) {
                    this.adoptionsCounty = defaultCountyOption;
                }
                else {
                    let filteredCounties  = [];
                    this.adoptionsCountyOptions.forEach(o => {
                        if (countyIDs.includes(o.value)) {
                            filteredCounties.push(o);
                        }
                    });
                    
                    if (app.signedInUser.UserType === 'Admin' && filteredCounties.length === 0) {
                        this.adoptionsCounty = defaultCountyOption;
                    }
                    else {
                        let sortedCounties = filteredCounties.sort((c) => c.CountyName);
                        const firstCounty = sortedCounties[0];
                        this.adoptionsCounty = firstCounty.value;
                    }
                }
            },
            setReductionDefaultCountyOption() {
                let countyIDs = app.signedInUser.CountyIDs;
                const defaultCountyOption = -3;
                if (app.signedInUser.UserType === 'Admin' && countyIDs.length === 0) {
                    this.reductionsCounty = defaultCountyOption;
                }
                else {
                    let filteredCounties  = [];
                    this.reductionsCountyOptions.forEach(o => {
                        if (countyIDs.includes(o.value)) {
                            filteredCounties.push(o);
                        }
                    });

                    if (app.signedInUser.UserType === 'Admin' && filteredCounties.length === 0) {
                        this.reductionsCounty = defaultCountyOption;
                    }
                    else {
                        let sortedCounties = filteredCounties.sort((c) => c.CountyName);
                        const firstCounty = sortedCounties[0];
                        this.reductionsCounty = firstCounty.value;
                    }
                }
            },
            exportOutcomes() {
                eventBus.$emit('showAppModal', this, 'outcomesExportModal', {
                    mode: this.$refs.outcomesTabs.ej2Instances.selectedItem === 0 ? 'exportReductions' : 'exportAdoptions',
                    cropYear: this.$refs.outcomesTabs.ej2Instances.selectedItem === 0 ? this.reductionsCropYear : this.adoptionsCropYear,
                    cropYearOptions: this.cropYearOptions,
                    tagOptions: this.tagOptions,
                });
            },
            showDisclaimer() {
                let message = 'The STAR Initiative is providing these estimations of environmental outcomes with the goal ' +
                              'of helping individual Licensees share programmatic impacts and successes at the county level. ' +
                              '<b>Licensees agree to use the export template</b> provided in outreach materials developed for their ' +
                              'counties, as the numbers that appear on this dashboard are not intended to be a replacement for ' +
                              'measurements verified via sampling and/or long-term monitoring at the field-scale.<br /><br />' +
                              'A rough approximation of nutrient, greenhouse gas (GHG), and sediment load reductions from ' +
                              'acres enrolled in the STAR were estimated utilizing the data sources, tools and equations ' +
                              'found in the Appendix of the ' +
                              '<a href="https://img1.wsimg.com/blobby/go/45c3f789-47fb-40df-9bb7-3dc4d7bf6c2f/downloads/STAR%20CY%202021%20Annual%20Report%20FINAL.pdf?ver=1663856240847" target="_blank">' +
                              'STAR CY2021 Annual Report</a>. All reported metrics have been calculated on a per practice ' +
                              'basis and are meant to provide an estimate of practice level ' +
                              'performance, as such the reported outcomes and metrics are not additive.'
                DM.alertShow(message, '', () => {
                    DM.localStorageSet('outcomesDisclaimer.StarPwaAdminLicensee', true);
                });
            }
        },
        created() {
            let hasSeenOutcomesDisclaimer = DM.localStorageGet('outcomesDisclaimer.StarPwaAdminLicensee');
            if(!hasSeenOutcomesDisclaimer) {
                this.showDisclaimer();
            }

            this.userIsAdmin = app.signedInUser.UserType === 'Admin';

            this.getCropYearOptions()
                .then((this.getAdoptionsCountyOptions))
                .then((this.getReductionsCountyOptions()))
                .then((this.getTagOptions))
                .then(response => {
                    this.setReductionDefaultCountyOption();
                    this.setAdoptionDefaultCountyOption();
                    this.getAdoptionsData();
                    this.getReductionsData();
                });
        },
    }
</script>
