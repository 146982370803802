<template src="./starScoresPerYearCardComponent.html"></template>

<script>
    import Vue from 'vue'
    import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
    import { ChartPlugin, StackingColumnSeries, Legend, Category, Tooltip, Highlight} from "@syncfusion/ej2-vue-charts";
    Vue.use(ChartPlugin);

    export default {
        name: 'star-scores-per-year-card-component',
        props: [
            'data',
        ],
        provide: {
            chart: [StackingColumnSeries, Category, Tooltip, Legend, Highlight]
        },
        data: function() {
            return {
                //Initializing Primary X Axis
                primaryXAxis: {
                    valueType: 'Category',
                    labelIntersectAction: 'Rotate45',
                    majorGridLines: { width: 0 },
                    minorGridLines: { width: 0 },
                    majorTickLines: { width: 0 },
                    minorTickLines: { width: 0 }
                },
                //Initializing Primary Y Axis
                primaryYAxis: {
                    rangePadding: 'None',
                    interval: 20,
                    majorTickLines: { width: 0 },
                    majorGridLines: { width: 1 },
                    minorTickLines: { width: 0 },
                    lineStyle: {
                        width: 0
                    }
                },
                chartArea: {
                    border: {
                        width: 0
                    }
                },
                tooltip: {
                    enable: true,
                    format: '${point.x} : <b>${point.y} field(s) (${point.percentage}%)</b>'
                },
                border: {color: '#ffffff', width:1},
                legend: {enableHighlight : true},
                width: '100%',
                title: ''
            }
        },
        methods: {
            tooltipRender(data) {
                let cropYear = this.data.StarScoreStatisticsResponse.CropYearStarScores.filter(s => s.CropYear === data.data.pointX)[0];
                let acres = cropYear.StarScores.filter(s => s.StarScore === (data.data.seriesIndex + 1))[0].Acres;
                data.text += ' <b>(' + acres + ' ac)</b>';
            }
        },
        created() {

        },
        mounted() {

        }
    }
</script>
