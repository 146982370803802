import Vue from 'vue'
import App from './App.vue';

import dmAlertModal from '../dmFramework/dmJS/dmAlertModal';
import dmConfirmModal from '../dmFramework/dmJS/dmConfirmModal';
import dmTable from '../dmFramework/dmJS/dmTable.vue';
import DM from '../dmFramework/dmJS/dmUtils';
import app from './js/app.js';
import axios from 'axios'
import moment from 'moment'
import store from './js/store.js';

import {L10n, registerLicense} from '@syncfusion/ej2-base';
import {ButtonPlugin, CheckBoxPlugin} from '@syncfusion/ej2-vue-buttons';
import {MenuPlugin, SidebarPlugin, TabPlugin} from '@syncfusion/ej2-vue-navigations';
import {GridPlugin, Sort} from '@syncfusion/ej2-vue-grids';
import {DialogPlugin} from '@syncfusion/ej2-vue-popups';
import {TextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import {DatePickerPlugin, DateRangePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {DropDownButtonPlugin} from "@syncfusion/ej2-vue-splitbuttons";
import {DropDownListPlugin, MultiSelectPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {UploaderPlugin} from '@syncfusion/ej2-vue-inputs';

let syncfusionLicenseKey = 'ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Vd0xiWH1acnZQRmVe';
registerLicense(syncfusionLicenseKey);

import './css/app.css'
import './css/syncFusion.css'
import '../dmFramework/dmCSS/dmGeneral.css'
import '../dmFramework/dmCSS/dmSizing.css'
import '../dmFramework/dmCSS/dmDisplay.css'
import '../dmFramework/dmCSS/dmLayout.css'
import '../dmFramework/dmCSS/dmColors.css'
import '../dmFramework/dmCSS/dmIcons.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome.min.css'
import '../dmFramework/dmIcons/faIcons/faCSS/font-awesome5_15_3.min.css'

Vue.component('dmAlertModal', dmAlertModal);
Vue.component('dmConfirmModal', dmConfirmModal);
Vue.component('dmTable', dmTable);

window.DM = DM.utils;
window.app = app;
window.axios = axios;
window.moment = moment;

L10n.load({
    'en-US': {
        'grid': {
            'EmptyRecord': 'No records to display.',
        },
    }
});

Vue.use(TabPlugin);
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(MenuPlugin);
Vue.use(SidebarPlugin);
Vue.use(GridPlugin);
Vue.use(DialogPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(UploaderPlugin);

let projectKey = '9f97a07a4ead4fe8a40c84b753ae2504';
Vue.config.errorHandler = (err, vm, info) => {
    // console.log(clickTracking);
    console.error(err);

    if(window.environmentName === 'dev') {
        return;
    }

    window.DM.sendErrorToDelMarMessaging({
        projectKey: projectKey,
        clicks: clickTracking,
        stackTrace: err.stack
    });
}

window.onerror = (message, source, lineno, colno, error) => {
    // console.log(clickTracking);
    // console.error(error);

    if(window.environmentName === 'dev') {
        return;
    }

    window.DM.sendErrorToDelMarMessaging({
        projectKey: projectKey,
        clicks: clickTracking,
        stackTrace: error.stack
    });
};

let clickTracking = [];
window.addEventListener('click', ($event) => {
    clickTracking.push($event.target.outerHTML);
    if (clickTracking.length > 10) {
        clickTracking.shift();
    }
});

window.eventBus = new Vue();

new Vue({
    el: '#app',
    store,
    render: h => h(App)
});

document.body.classList.add('dm-env-' + window.environmentName);