import Vue from 'vue';
import Vuex from 'vuex';

// Modules

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        localStoragePrefix: 'star-pwa-admin-licensee-experience-frontend-',
        googleMapsIsLoaded: false
    },
    mutations: {
        setGoogleMapsIsLoaded(state, googleMapsIsLoaded) {
            state.googleMapsIsLoaded = googleMapsIsLoaded;
        },
    },
    actions: {
        setGoogleMapsIsLoaded({commit}, googleMapsIsLoaded) {
            commit('setGoogleMapsIsLoaded', googleMapsIsLoaded);
        },
    }
});

export default store;
