<template src="./tagsPage.html"></template>

<script>
    import Vue from 'vue';

    export default {
        name: 'tags-page',
        props: [
            'pageData',
        ],
        data: function() {
            return {
                grdTagFields: [],
                grdTagItems: [],
                grdTagSortBy: {
                    columns: [
                        {field: 'TagName', direction: 'Ascending'},
                    ]
                },
                grdTagClass: '',
                grdTagAllowPaging: true,
                grdTagPageSettings: {pageSize: 30},
                searchCropYear: '',
                cropYearOptions: [],
                searchFarmerName: '',
                farmerNameOptions: [],
                searchCounty: '',
                countyOptions: [],
                searchCropType: '',
                cropTypeOptions: [],
                searchText: '',
                grdTagActions: [
                    {
                        text: 'Edit Tag'
                    },
                ],
                selectedRow: null
            }
        },
        methods: {
            getTags() {
                DM.http({
                    method: 'GET',
                    url: '/Tags/GetAll',
                    params: { IncludeInactive: true }
                }).then(response => {
                    this.grdTagItems = response;
                });
            },
            getTagByID(tagID) {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetByID',
                    params: { TagID: tagID },
                }).then((response) => {
                    return response;
                });
            },
            grdTagRowOnClick(args) {
                this.selectedRow = args;
            },
            grdTagActionOnClick(args) {
                if (args.item.text === 'Edit Tag') {
                    this.getTagByID(this.selectedRow.rowData.TagID).then((response) => {
                        eventBus.$emit('showAppModal', this, 'tagsEditModal', {
                            mode: 'edit',
                            tag: response,
                            callback: this.getTags,
                        });
                    });
                }
            },
            addTagOnClick() {
                eventBus.$emit('showAppModal', this, 'tagsEditModal', {
                    mode: 'new',
                    tag: {},
                    callback: this.getTags,
                });
            },
        },
        created() {
            if(this.pageData && this.pageData.userID) {
                this.searchFarmerName = this.pageData.userID;
            }

            this.getTags();

            let self = this;
            this.grdTagFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdTagActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass="e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdTagActions: self.grdTagActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdTagActionOnClick(args);
                                    }
                                },
                            })
                        }
                    }
                },
                {
                    key: 'TagName',
                    label: 'Program/Project Name',
                    allowSorting: true
                },
                {
                    key: 'TagNotes',
                    label: 'Program/Project Notes',
                    allowSorting: true
                },
                {
                    key: 'IsActive',
                    label: 'Is Active',
                    width: '150px',
                    type: 'custom',
                    formatter: (field, data) => {
                        return data.IsActive ? 'Y' : 'N';
                    },
                    textAlign: 'Center',
                },
            ];
        },
    }
</script>
