<template src="./passwordEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../js/states.js'

    export default {
        name: 'password-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'passwordEditModal' + DM.getRandomGuid(),
                lblTitle: 'Change Password',
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            }
        },
        methods: {
            changePassword() {
                return DM.http({
                    method: "POST",
                    url: '/Users/UpdatePassword',
                    data: {
                        CurrentPassword: this.currentPassword,
                        NewPassword: this.newPassword,
                        NewPasswordConfirm: this.newPasswordConfirm,
                    }
                });
            },
            btnSaveOnClick() {
                this.changePassword()
                    .then(response => {
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
        },
        created() {

        },
        mounted() {

        }
    }
</script>
