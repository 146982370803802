<template src="./outcomesExportModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
    MultiSelect.Inject(CheckBoxSelection);

    export default {
        name: 'outcomes-export-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'outcomesExportModal' + DM.getRandomGuid(),
                lblTitle: 'Export',
                practices: [],
                practicesOptions: [],
                cropYear: null,
                tags: [],
                counties: [],
                countiesOptions: []
            }
        },
        computed: {
            mode() {
                return this.modalData.mode;
            },
            tagsOptions() {
                return this.modalData.tagOptions;
            },
            cropYearOptions() {
                return this.modalData.cropYearOptions;
            }
        },
        methods: {
            getPracticeOptions() {
                let url = '';
                if(this.mode === 'exportReductions') {
                    url = '/Outcomes/GetReductionSections';
                } else if(this.mode === 'exportAdoptions') {
                    url = '/Outcomes/GetAdoptionSections';
                }

                return DM.http({
                    method: 'GET',
                    url: url
                }).then(response => {
                    response.forEach(p => {
                        let formattedOption = {text: p.Value, value: p.Value};
                        this.practicesOptions.push(formattedOption);
                    });
                });
            },
            exportOutcomes() {
                const dto = this.getDto();

                return DM.http({
                    method: 'POST',
                    url: '/Tags/UpdateFieldAssignmentTags',
                    data: dto,
                }).then((response) => {
                    return response;
                });
            },
            btnExportOnClick() {
                let url = '';
                if(this.mode === 'exportReductions') {
                    url = '/Outcomes/ExportReductions';
                } else if(this.mode === 'exportAdoptions') {
                    url = '/Outcomes/ExportAdoptions';
                }

                this.exportCsv(url);
            },
            exportCsv(url) {
                DM.addRequest();
                axios({
                    method: 'POST',
                    url: window.apiRoot + url,
                    data: {
                        CropYear: this.cropYear,
                        OutcomePractices: this.practices,
                        TagIDs: this.tags,
                        CountyIDs: this.counties
                    },
                    headers: {Authorization: 'Bearer ' + DM.authToken},
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        let content = response.headers['content-disposition'];
                        let filename = content.split(';')[1].replace('filename=', '').replace('"', '').replace('"', '').trim();
                        this.downloadAjaxResponseAsFile(response, filename)
                    })
                    .catch((response) => {
                        try {
                            response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.response.data)));
                        } catch (err) {

                        }

                        let errorString = response.data.Error ? response.data.Error : "An unexpected error has occurred on the server.";
                        DM.alertShow(errorString, 'Error');
                    })
                    .finally(() => {
                        DM.removeRequest();
                    });
            },
            downloadAjaxResponseAsFile(response, fileName) {
                let blob = new Blob([response.data], {type: 'text/csv'});
                let objectUrl = URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = objectUrl;
                a.download = fileName;
                a.click();
                document.body.removeChild(a);
            },
            btnCancelOnClick() {
                if (this.modalData.callback) {
                    this.modalData.callback();
                }

                this.modalRef.hide();
            },
            getCountyOptions(outcomeType) {
                return DM.http({
                    method: 'GET',
                    url: '/Outcomes/GetCounties',
                    params: {
                        OutcomeType: outcomeType,
                        IncludeAggregates: true
                    }
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = { text: type.DisplayText, value: type.CountyID };
                        this.countiesOptions.push(dataObject);
                    });
                });
            },
        },
        created() {
            this.getPracticeOptions();
            this.cropYear = this.modalData.cropYear || this.cropYearOptions[0].value;

            if (this.mode === 'exportReductions') {
                this.lblTitle = 'Export Reductions';
                this.getCountyOptions('Reductions');
            } else if(this.mode === 'exportAdoptions') {
                this.lblTitle = 'Export Adoptions';
                this.getCountyOptions('Adoptions');
            }
        },
    }
</script>
