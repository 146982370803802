<template src="./fieldsAssignmentEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'fields-assignment-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'fieldsAssignmentEditModal' + DM.getRandomGuid(),
                lblTitle: 'Reassign Field',
                selectedUser: '',
                userOptions: [],
                notes: '',
                field: null
            }
        },
        methods: {
            saveField() {
                let url = '/FieldAssignments/UpdateForAnotherUser';

                let data = {
                    FieldAssignmentID: this.field.FieldAssignmentID,
                    Notes: this.notes,
                    UserID: this.selectedUser || -1
                };

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                });
            },
            btnSaveOnClick() {
                this.saveField()
                    .then(response => {
                        this.modalData.callback();
                        this.modalRef.hide();
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            getUsers() {
                DM.http({
                    method: 'GET',
                    url: '/Users/GetAll',
                    params: {
                        Type: 'Farmer'
                    }
                }).then(response => {
                    response = response.filter(u => u.IsInactive === false);
                    this.userOptions = response.map(u => { return {text: u.LastName + ', ' + u.FirstName + ' - ' + u.EmailAddress, value: u.UserID} });
                });
            }
        },
        created() {
            this.getUsers();

            this.field = this.modalData.field;

            if(this.modalData.mode === 'view') {
                this.notes =  this.field.Notes;
            }
        },
        mounted() {

        }
    }
</script>
