//import { createSpinner, showSpinner, hideSpinner, setSpinner } from '@syncfusion/ej2-popups';
export default {
    utils: {
        requests: 0,
        authToken: null,
        http(config, hideLoader) {
            const appServer = window.apiRoot;

            if(!hideLoader) {
                DM.addRequest();
            }

            if (config.url) {
                config.url = appServer + config.url;
            } else {
                config.url = appServer;
            }

            if (this.authToken !== null) {
                config.headers = config.headers || {};
                config.headers.Authorization = "Bearer " + this.authToken;
            }

            return axios(config)
                .then(response => {
                    return response.data;
                }, DM.handleServerError)
                .finally(() => {
                    if(!hideLoader) {
                        DM.removeRequest();
                    }
                });
        },
        sendErrorToDelMarMessaging(data) {
            return axios({
                method: 'POST',
                url: 'https://dev-server.delmarsd.com/DelMarMessaging/Messaging/FrontEndLog',
                data: data
            })
                .then(response => {
                    return response.data;
                });
        },
        handleServerError(data) {
            let response = data.response;
            let unexpectedErrorTitle = "System Error:";
            let unexpectedErrorMessage = "An unexpected error has occurred on the server.";

            if (response) {
                if (response.status >= 400 && response.status < 500) {
                    let errorString = "";

                    if (response.config.responseType === "arraybuffer") {
                        try {
                            response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(response.data)));
                        } catch (err) {
                            DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
                        }
                    }

                    errorString = response.data.Error;

                    let callback = () => {

                    };

                    if (response.status === 401) {
                        eventBus.$emit('resetUserToSignIn');
                    } else {
                        DM.alertShow(errorString, "Error", callback);
                    }
                } else {
                    DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
                }
            } else {
                DM.alertShow(unexpectedErrorMessage, unexpectedErrorTitle);
            }
            return Promise.reject(response);
        },

        confirmShow(message, title, confirm, deny, confirmText, closeText) {
            eventBus.$emit('showAppModal', this, 'dmConfirmModal', {
                title: title,
                message: message,
                confirm: confirm,
                deny: deny,
                confirmText: confirmText,
                closeText: closeText
            });
        },

        alertShow(message, title, callback) {
            eventBus.$emit('showAppModal', this, 'dmAlertModal', {
                title: title,
                message: message,
                callback: callback,
            });
        },

        addRequest() {
            this.requests++;
            this.spinnerToggleForRequest(true);
        },

        removeRequest() {
            this.requests--;
            this.spinnerToggleForRequest(false);
        },

        spinnerToggleForRequest(show) {
            if (show && this.requests === 1) {
                this.spinnerShow();
            }

            if (this.requests === 0) {
                this.spinnerHide();
            }
        },

        spinnerShow() {
            let loading = document.createElement('div');
            loading.id = 'dm-spinner-active';
            loading.innerHTML = '<div class="flex-column"><div></div>Loading...</div>';
            loading.classList.add('dm-spinner');
            loading.style.display = 'flex';
            document.querySelector('body').appendChild(loading);
        },

        spinnerHide() {
            document.getElementById('dm-spinner-active').remove();
        },

        localStorageSet: function (key, value) {
            let type = typeof value;
            value = type === 'Object' || type === 'Array' ? JSON.stringify(value) : value;
            localStorage.setItem(key, value);
        },

        localStorageGet: function (key) {
            return localStorage.getItem(key) || null;
        },

        localStorageRemove: function (key) {
            localStorage.removeItem(key);
        },

        getCboOptions(options, value, text, noSort) {
            if (value) {
                options = options.map(r => {
                    return {text: r[text], value: r[value]}
                });
            } else {
                options = options.map(r => {
                    return {text: r, value: r}
                });
            }

            if (noSort) {
                return options;
            } else {
                return this.sortObjectArray(options, 'text');
            }
        },

        sortObjectArray(array, property) {
            let compare = (a, b) => {
                if (a[property] < b[property])
                    return -1;
                if (a[property] > b[property])
                    return 1;
                return 0;
            };

            return array.sort(compare);
        },

        sortArray(array) {
            let compare = (a, b) => {
                if (a < b)
                    return -1;
                if (a > b)
                    return 1;
                return 0;
            };

            return array.sort(compare);
        },

        getRandomGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },

        objectAreNotEqual(obj1, obj2, props) {
            let isNotEqual = false;

            props.forEach(p => {
                if (JSON.stringify(obj1[p]) !== JSON.stringify(obj2[p])) {
                    // console.log(p);
                    // console.log(JSON.stringify(obj1[p]), JSON.stringify(obj2[p]));
                    isNotEqual = true;
                }
            });
            return isNotEqual;
        },

        checkForChanges(scope, propsToCheck, callback) {
            if (DM.objectAreNotEqual(scope.savedInitialData, scope, propsToCheck)) {
                this.confirmClose(callback);
            } else {
                callback();
            }
        },

        confirmClose(closeCallback) {
            this.confirmShow('You have unsaved changes. Are you sure you want to close?', 'Confirm', closeCallback);
        },

        objectToQueryString(params) {
            return Object.keys(params).map(key => key + '=' + params[key]).join('&');
        },

        downloadAjaxResponseAsFile: function (blob, fileName) {
            //let blob = new Blob(response.FileData, { type: response.ContentType });
            let objectUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = objectUrl;
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
        },

        copy(data) {
            return JSON.parse(JSON.stringify(data));
        },

        round(number, decimalPlaces) {
            let precision = 10;
            for (let x = precision; x >= decimalPlaces; x--) {
                number = number.toFixed(x);
                number = parseFloat(number);
            }
            return number;
        },

        displayNumber(number, decimalPlaces) {
            let roundedNumber = this.round(number, decimalPlaces);
            return roundedNumber.toLocaleString(undefined, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces
            });
        },

        formatDateDisplay(dateString) {
            if (!dateString) {
                return null;
            }
            return moment.utc(dateString).format('M/D/YYYY');
        },

        formatTimeDisplay(dateString) {
            if (!dateString) {
                return null;
            }
            return moment.utc(dateString).format('h:mma');
        },

        formatDateInput(dateString) {
            if (!dateString) {
                return null;
            }
            return moment.utc(dateString).format('YYYY-MM-DD');
        },

        formatTimeInput(dateString) {
            if (!dateString) {
                return null;
            }
            return moment.utc(dateString).format('HH:mm');
        },

        getCurrentYear() {
            return moment().year();
        },

        isNumTruthy(number) {
            return number !== undefined && number !== '' && !isNaN(parseFloat(number));
        },
    }
};
