<template src="./usersEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'
    import states from '../../js/states.js'

    export default {
        name: 'users-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'usersEditModal' + DM.getRandomGuid(),
                lblTitle: 'Add User',
                userType: '',
                userTypeOptions: null,
                emailAddress: '',
                password: '',
                passwordConfirm: '',
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                stateOptions: states.map(s => { return {value: s, text: s}}),
                zipCode: '',
                county: '',
                officePhone: '',
                mobilePhone: '',
                organization: '',
                townshipSectionRange: '',
                ownerName: '',
                counties: [],
                countyOptions: [],
                isMainContact: false,
                region: null,
                regionOptions: [],
                isActive: true,
                mode: '',
                userIsAdmin: app.signedInUser.UserType === 'Admin'
            }
        },
        methods: {
            saveUser() {
                let url = '/Users/AdminCreateAccount';
                let data = DM.copy(this.modalData.user);

                if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
                    data.UserID = this.modalData.user.UserID;
                    url = '/Users/Update';
                }

                Object.assign(data, {
                    EmailAddress: this.emailAddress,
                    Password: this.modalData.mode === 'edit' ? undefined : this.password,
                    PasswordConfirm: this.modalData.mode === 'edit' ? undefined : this.passwordConfirm,
                    UserType: this.userType || '',
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    StreetAddress: this.address1,
                    StreetAddress2: this.address2,
                    City: this.city,
                    State: this.state || '',
                    ZipCode: this.zipCode,
                    County: this.county,
                    OwnerName: this.ownerName,
                    OfficePhone: this.officePhone,
                    MobilePhone: this.mobilePhone,
                    SectionTownshipAndRange: this.townshipSectionRange,
                    Organization: this.organization,
                    CountyIDs: this.counties,
                    IsMainContact: this.isMainContact,
                    IsInactive: !this.isActive,
                    VerificationRegionID: this.region || 0
                });

                return DM.http({
                    method: "POST",
                    url: url,
                    data: data
                }).then(response => {
                    return data;
                })
            },
            btnSaveOnClick() {
                let save = () => {
                    this.saveUser()
                        .then(response => {
                            this.modalData.callback(response);
                            this.modalRef.hide();
                        });
                };

                if(!this.isActive && this.userType === 'Verifier') {
                    DM.confirmShow(
                        'Taking this action can remove a verifier from current crop year fields. Check the Verifications page for any fields needing reassignment.',
                        'Warning',
                        save,
                        undefined,
                        'OK',
                        'Cancel'
                    );
                } else {
                    save();
                }
            },
            btnSaveAndAddMoreOnClick() {
                this.saveUser()
                    .then(response => {
                        this.modalData.callback();
                        this.userType = null;
                        this.emailAddress = '';
                        this.password = '';
                        this.passwordConfirm = '';
                        this.firstName = '';
                        this.lastName = '';
                        this.address1 = '';
                        this.address2 = '';
                        this.city = '';
                        this.state = null;
                        this.zipCode = '';
                        this.county = '';
                        this.officePhone = '';
                        this.mobilePhone = '';
                        this.ownerName = '';
                        this.townshipSectionRange = '';
                        this.organization = '';
                        this.counties = [];
                        this.region = null;

                        setTimeout(() => {
                            this.isActive = true;
                        },300);
                    });
            },
            btnCancelOnClick() {
                this.modalRef.hide();
            },
            btnChangePasswordOnClick() {
                eventBus.$emit('showAppModal', this, 'passwordEditModal', {

                });
            },
            countiesOnChange($event) {

            },
            getRegionOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Verifications/GetRegions',
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.RegionName, value: type.VerificationRegionID};
                        this.regionOptions.push(dataObject);
                    });
                });
            },
        },
        created() {
            this.getRegionOptions();

            let user = this.modalData.user;
            this.userTypeOptions = this.modalData.userTypeOptions;
            this.countyOptions = this.modalData.countyOptions;

            this.mode = this.modalData.mode;

            if (this.modalData.mode === 'edit' || this.modalData.mode === 'profile') {
                if(this.modalData.mode === 'edit') {
                    this.lblTitle = 'Edit User: ' + this.modalData.user.FirstName + ' ' + this.modalData.user.LastName;
                }

                if(this.modalData.mode === 'profile') {
                    this.lblTitle = 'My Profile';
                }

                this.userType = user.UserType;
                this.emailAddress = user.EmailAddress;
                this.firstName = user.FirstName;
                this.lastName = user.LastName;
                this.address1 = user.StreetAddress;
                this.address2 = user.StreetAddress2;
                this.city = user.City;
                this.state = user.State;
                this.zipCode = user.ZipCode;
                this.county = user.County;
                this.officePhone = user.OfficePhone;
                this.mobilePhone = user.MobilePhone;
                this.townshipSectionRange = user.SectionTownshipAndRange;
                this.organization = user.Organization;
                this.ownerName = user.OwnerName;
                this.isMainContact = user.IsMainContact;
                this.isActive = !user.IsInactive;
                this.counties = user.CountyIDs;
                this.region = user.VerificationRegionID;
            }

            this.userTypeOptions = this.modalData.userTypeOptions;
        },
        mounted() {

        }
    }
</script>
