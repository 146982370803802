<template src="./fieldsUploadModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'fields-upload-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'fieldsUploadModal' + DM.getRandomGuid(),
                lblTitle: 'Add Field',
                dropElement: '#droparea',
                path:  {
                    saveUrl: window.apiRoot + '/FieldForms/ImportFieldForm',
                },
                success: false
            }
        },
        methods: {
            btnCancelOnClick() {
                if(this.success) {
                    this.modalData.callback();
                }

                this.modalRef.hide();
            },
            uploadSuccess() {
                this.success = true;
            },
            uploadBefore(event, file, name) {
                event.currentRequest.setRequestHeader('Authorization', 'Bearer ' + DM.authToken);

                event.currentRequest.onreadystatechange = function(e) {
                    if(event.currentRequest.readyState === XMLHttpRequest.DONE) {
                        var status = event.currentRequest.status;
                        if (status === 0 || (status >= 200 && status < 400)) {

                        } else {
                          DM.alertShow(JSON.parse(e.currentTarget.response).Error, 'Error');
                        }
                    }
                }
            }
        },
        created() {

        },
        mounted() {

        }
    }
</script>
