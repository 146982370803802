<template src="./fieldTagsEditModal.html"></template>

<script>
    import dmModalMixin from '../../../dmFramework/dmJS/dmModalMixin'

    export default {
        name: 'field-tags-edit-modal',
        mixins: [dmModalMixin],
        props: [
            "modalData",
        ],
        data: function () {
            return {
                modalName: 'tagsEditModal' + DM.getRandomGuid(),
                lblTitle: 'Edit Programs/Projects',
                tagName: '',
                tagNotes: '',
                fieldTagOptionMapping: { text: 'TagName', value: 'TagID' },
                fieldTagOptions: [],
                selectedTags: [],
                selectedTagIDs: [],
                isActive: true,
            }
        },
        computed: {
            mode() {
                return this.modalData.mode;
            },
        },
        methods: {
            getTagsForField() {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetTagsForFieldAssignment',
                    params: { FieldAssignmentID: this.modalData.field.FieldAssignmentID },
                }).then((response) => {
                    return response;
                });
            },
            getAllTags() {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetAll',
                    params: { IncludeInactive: false }
                }).then((response) => {
                    this.fieldTagOptions = response;
                    return response;
                });
            },
            getDto() {
                let incomingDto = this.modalData.field;
                let tagList = [];
                const existingTagIDs = this.selectedTags.map((t) => { return t.TagID });

                for (var i = 0; i < this.selectedTagIDs.length; i++) {
                    const tagID = this.selectedTagIDs[i];

                    let tagDto = 
                    {
                        FieldAssignmentID: incomingDto.FieldAssignmentID,
                        TagID: tagID
                    }

                    if (existingTagIDs.includes(tagID)) {
                        tagDto.FieldAssignmentTagID = this.selectedTags.filter((t) => t.TagID === tagID)[0].FieldAssignmentTagID;
                    }

                    tagList.push(tagDto);
                }

                let dto = 
                { 
                    FieldAssignmentID: incomingDto.FieldAssignmentID,
                    Tags: tagList,
                    TagNotes: this.tagNotes,
                }

                return dto;
            },
            saveTag() {
                const dto = this.getDto();

                return DM.http({
                    method: 'POST',
                    url: '/Tags/UpdateFieldAssignmentTags',
                    data: dto,
                }).then((response) => {
                    return response;
                });
            },
            btnSaveOnClick() {
                this.saveTag().then((response) => {
                    this.btnCancelOnClick();
                });
            },
            btnCancelOnClick() {
                if (this.modalData.callback) {
                    this.modalData.callback();
                }

                this.modalRef.hide();
            },
        },
        created() {
            this.getAllTags().then(() => {
                this.getTagsForField().then((response) => {
                    this.selectedTags = response.Tags;
                    const selectedTagIDs = response.Tags.map((t) => { return t.TagID });
                    this.selectedTagIDs = [...selectedTagIDs];
                    this.tagNotes = response.TagNotes;
                });
            });
            
            if (this.mode === 'edit') {
                this.lblTitle = 'Edit Programs/Projects: ' + this.modalData.field.FieldName;
            }
        },
    }
</script>
