<template src="./farmersPage.html"></template>

<script>
    import Vue from 'vue'

    export default {
        name: 'farmers-page',
        data: function () {
            return {
                grdFarmersFields: [],
                grdFarmersItems: [],
                grdFarmersSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdFarmersClass: '',
                searchCounty: '',
                countyOptions: [],
                searchText: '',
                grdFarmersActions: [
                    {
                        text: 'View Fields'
                    },
                ],
                selectedRow: null
            }
        },
        methods: {
            getFarmers() {
                DM.http({
                    method: 'GET',
                    url: '/Users/GetAll',
                    params: {
                        Type: 'Farmer'
                    }
                }).then(response => {
                    response.forEach(u => {
                        u.countyNames = this.countyFilter(u);
                    });
                    this.farmers = response;
                    this.grdFarmersItems = response;
                });
            },
            getCountyOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Counties/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.DisplayText, value: type.CountyID};
                        this.countyOptions.push(dataObject);
                    });
                });
            },
            grdFarmersRowOnClick(args) {
                this.selectedRow = args;
            },
            grdFarmersActionOnClick(args) {
                if(args.item.text === 'View Fields') {
                    this.$emit('loadPage', 'fieldsPage', {
                        userID: this.selectedRow.rowData.UserID
                    })
                }
            },
            searchFarmersOnClick() {
                DM.http({
                    method: 'GET',
                    url: '/Users/GetAll',
                    params: {
                        Type: 'Farmer',
                        CountyID: this.searchCounty,
                        TextSearch: this.searchText
                    }
                }).then(response => {
                    this.farmers = response;
                    this.grdFarmersItems = response;
                });
            },
            countyFilter(data) {
                // Note: Filter out any unsupported counties to prevent table row insertion from breaking -- Andrew 12/28/2021

                let validCountyIDs = [];
                data.CountyIDs.map(c => {
                    if (this.countyOptions.filter(o => o.value === c).length > 0) {
                        validCountyIDs.push(c);
                    }
                });

                data.CountyIDs = validCountyIDs;

                return data.CountyIDs.map(c => this.countyOptions.filter(o => c === o.value)[0].text).join(', ');
            },
        },
        created() {
            this.getCountyOptions()
                .then(response => {
                    this.getFarmers();
                });

            let self = this;

            this.grdFarmersFields = [
                {
                    type: 'template',
                    width: '80px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdFarmersActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdFarmersActions: self.grdFarmersActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdFarmersActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: "LastName",
                    label: "Last Name",
                    allowSorting: true
                },
                {
                    key: "FirstName",
                    label: "First Name",
                    allowSorting: true
                },
                {
                    key: "EmailAddress",
                    label: "Email",
                    allowSorting: true
                },
                {
                    key: "countyNames",
                    label: "County(ies)",
                    allowSorting: true
                },
                {
                    key: "Active",
                    label: "Active",
                    formatter: (field, data) => {
                        return data.IsInactive ? 'N' : 'Y';
                    },
                    width: '70px',
                    textAlign: 'Center',
                    allowSorting: false
                },
            ];
        },
        mounted() {

        }
    }
</script>
