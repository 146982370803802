<template src="./resourcesPage.html"></template>

<script>
    export default {
        name: 'resources-page',
        data: function () {
            return {
                resources: null
            }
        },
        methods: {
            getResources() {
                DM.http({
                    method: 'GET',
                    url: '/Resources/GetAll',
                    params: {
                        Type: 'Farmer'
                    }
                }).then(response => {
                    this.resources = response;
                });
            }
        },
        created() {
            this.getResources();
        },
        mounted() {

        }
    }
</script>
