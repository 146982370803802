<template src="./verificationsPage.html"></template>

<script>
    import Vue from 'vue';
    import dummyDataObj from './verificationsData.js';

    export default {
        name: 'verifications-page',
        data: function () {
            return {
                grdVerificationsFields: [],
                grdVerificationsItems: [],
                grdVerificationsSortBy: {
                    columns: [
                        {field: 'LastName', direction: 'Ascending'},
                    ]
                },
                grdVerificationsClass: '',

                cropYearOptions: [],
                countyOptions: [],
                regionOptions: [],
                verifierOptions: [],

                cropYear: null,
                county: null,
                region: null,
                verifier: null,
                searchText: '',

                grdVerificationsActions: [
                    {
                        text: 'Edit'
                    }
                ],
                selectedRow: null
            }
        },
        methods: {
            getVerificationsData() {
                return DM.http({
                    method: 'POST',
                    url: '/Verifications/GetAll',
                    data: {
                        CropYear: this.cropYear || 2021,
                        CountyID: this.county || -1,
                        VerificationRegionID: this.region || -1,
                        VerifierUserID: this.verifier || -1,
                        TextSearch: this.searchText || '',
                    }
                }).then(response => {
                    this.grdVerificationsItems = response;
                });
            },
            getCropYearOptions() {
                DM.http({
                    method: 'GET',
                    url: '/Crops/GetAllYears',
                }).then(response => {
                    this.cropYearOptions = response.CropYears.map(y => {
                        return {value: y, text: y}
                    });
                });
            },
            getCountyOptions() {
                return DM.http({
                    method: "GET",
                    url: "/Counties/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.CountyName, value: type.CountyID};
                        this.countyOptions.push(dataObject);
                    });
                });
            },
            getRegionOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Verifications/GetRegions',
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.RegionName, value: type.VerificationRegionID};
                        this.regionOptions.push(dataObject);
                    });
                });
            },
            grdVerificationsRowOnClick(args) {
                this.selectedRow = args;
            },
            grdVerificationsActionOnClick(args) {
                if (args.item.text === 'Edit') {
                    this.openEditVerificationModal();
                }
            },
            openEditVerificationModal() {
                DM.http({
                    method: 'GET',
                    url: '/Verifications/GetByID',
                    params: {
                        FieldVerificationID: this.selectedRow.rowData.FieldVerificationID
                    }
                })
                    .then(response => {
                        let verifier = this.verifierOptions.filter(v => v.value == this.selectedRow.rowData.VerifierUserID)[0];

                        eventBus.$emit('showAppModal', this, 'verificationsEditModal', {
                            mode: 'edit',
                            verification: response,
                            verifier: verifier ? verifier.text : '',
                            userTypeOptions: this.userTypeOptions,
                            countyOptions: this.countyOptions,
                            callback: (verification) => {
                                this.getVerificationsData();
                            }
                        });
                    });
            },
            searchVerificationsOnClick() {
                this.getVerificationsData();
            },
            selectFieldsForVerificationOnClick() {
                let selectFields = () => {
                    DM.http({
                        method: 'POST',
                        url: '/Verifications/SelectFieldsForVerification',
                    })
                        .then(response => {
                            this.getVerificationsData();
                            DM.alertShow('Fields have been selected for verification.');
                        });
                }

                DM.confirmShow(
                    'Are you sure you want to select fields for verification?',
                    '',
                    selectFields,
                    undefined,
                    'Yes',
                    'Cancel'
                );
            },
            getVerifierOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Verifications/GetVerifiers',
                })
                    .then(response => {
                        response.forEach(v => {
                            let dataObject = {text: v.LastName + ', ' + v.FirstName, value: v.UserID, regionName: v.VerificationRegion.RegionName};
                            this.verifierOptions.push(dataObject);
                        });
                    });
            },
            grdVerificationsVerifierChanged(verification, verifierID) {
                let verificationCopy = DM.copy(verification);
                delete verificationCopy.column;
                delete verificationCopy.index;
                DM.http({
                    method: 'POST',
                    url: '/Verifications/Update',
                    data: Object.assign(verificationCopy, {
                        VerifierUserID: verifierID || 0
                    })
                });
            }
        },
        computed: {
            signedInUserIsAdmin() {
                return app.signedInUser.UserType === 'Admin';
            },
        },
        created() {
            this.getVerificationsData();
            this.getCropYearOptions();
            this.getCountyOptions();
            this.getRegionOptions();
            this.getVerifierOptions();

            let self = this;

            let verifierField;
            if(this.signedInUserIsAdmin) {
                verifierField = {
                    label: 'Verifier',
                    type: 'template',
                    width: '170px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownlist
                                                v-model="data.VerifierUserID"
                                                :dataSource="verifierOptions"
                                                :fields="{ text: 'text', value: 'value' }"
                                                :width="'100%'"
                                            >
                                           </ejs-dropdownlist>`,
                                computed: {
                                    verifierOptions: function() {
                                        return self.verifierOptions.filter(v => v.regionName === this.data.RegionName);
                                    },
                                },
                                created() {
                                    this.$watch('data.VerifierUserID', (newVal) => {
                                        self.grdVerificationsVerifierChanged(this.data, newVal);
                                    });
                                }
                            })
                        }
                    }
                };
            } else {
                verifierField = {
                    label: 'Verifier',
                    formatter: (field, data) => {
                        if(data.VerifierUserID) {
                            let verifier = self.verifierOptions.filter(v => v.value == data.VerifierUserID)[0];
                            return verifier ? verifier.text : '';
                        }
                        return '';
                    },
                    allowSorting: true,
                    width: '150px'
                };
            }

            this.grdVerificationsFields = [
                {
                    type: 'template',
                    width: '50px',
                    template: () => {
                        return {
                            template: Vue.component('columnTemplate', {
                                template: `<ejs-dropdownbutton
                                                :items="grdVerificationsActions"
                                                :select="select"
                                                iconCss="e-icons e-ellipsis-vert"
                                                cssClass= "e-caret-hide">
                                           </ejs-dropdownbutton>`,
                                data: function() {
                                    return {
                                        grdVerificationsActions: self.grdVerificationsActions
                                    }
                                },
                                methods: {
                                    select(args) {
                                        self.grdVerificationsActionOnClick(args);
                                    }
                                }
                            })
                        }
                    }
                },
                {
                    key: "FieldName",
                    label: "Field Name",
                    allowSorting: true
                },
                {
                    key: "FarmerName",
                    label: "Farmer Name",
                    allowSorting: true
                },
                {
                    key: "CropType",
                    label: "Crop Type",
                    allowSorting: true,
                    width: '90px'
                },
                {
                    key: "FieldAssignmentID",
                    label: "Assignment ID",
                    allowSorting: true
                },
                {
                    key: "CountyName",
                    label: "County",
                    allowSorting: true
                },
                {
                    key: "RegionName",
                    label: "Region",
                    allowSorting: true,
                    width: '80px',
                },
                {
                    key: "StarScore",
                    label: "Star Score",
                    textAlign: 'center',
                    allowSorting: true,
                    width: '80px',
                },
                {
                    key: "ImprovementPlan",
                    label: "Improvement Plan",
                    allowSorting: true,
                    formatter: (field, data) => {
                        return data.HasImprovementPlan ? 'Y' : 'N';
                    },
                    width: '110px',
                    textAlign: 'center',
                },
                verifierField,
                {
                    key: "VerificationProgress",
                    label: "Verification Progress",
                    allowSorting: true,
                    width: '120px'
                },
                {
                    key: "CompletedUtc",
                    label: "Verification Completed",
                    formatter: (field, data) => {
                        return data.HasCompletedVerification ? DM.formatDateDisplay(data.CompletedUtc) : '';
                    },
                    allowSorting: true,
                    width: '100px',
                },
                {
                    key: "VerificationPassed",
                    label: "Verification Passed",
                    formatter: (field, data) => {
                        return data.VerificationPassed ? 'Y' : 'N'
                    },
                    allowSorting: true,
                    textAlign: 'center',
                    width: '100px',
                },
                {
                    key: 'DocumentsReceived',
                    label: 'Files',
                    width: '80px',
                    formatter: (field, data) => {
                        return data.DocumentsReceived ? 'Y' : 'N'
                    },
                    allowSorting: true,
                    textAlign: 'center',
                },
            ];
        },
    }
</script>
