<template src="./dashboardPage.html"></template>

<script>
    import Vue from 'vue'
    import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
    Vue.use(TabPlugin);
    import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
    MultiSelect.Inject(CheckBoxSelection);

    import starScoresPerYearCardComponent from '../starScoresPerYearCardComponent/starScoresPerYearCardComponent'
    import DmUtils from '../../../dmFramework/dmJS/dmUtils';
    let simpleCardComponent = Vue.component('card', {
        props: [
            'data'
        ],
        template: `<div class="app-stat-card dm-margin-right-md dm-margin-bottom-md">

                        <div>
                            <span v-html="data.StatisticName" style="color: rgba(0, 0, 0, 0.42);"></span>
                        </div>

                        <div>
                            <span v-html="data.StatisticValue" :style="'font-size: ' + data.fontSize + ';'"></span>
                        </div>

                    </div>`,
    });

    export default {
        name: 'dashboard-page',
        components: {
            simpleCardComponent,
            starScoresPerYearCardComponent
        },
        data: function () {
            return {
                activeTabIndex: 0,
                cropYear: -1,
                cropYearOptions: [],
                county: null,
                countyOptions: [],
                tags: [],
                tagOptions: [ { text: 'Untagged', value: 0 } ],
                mapStatistic: null,
                mapStatisticOptions: [],

                statistics: [],
                map: null,
                counties: {},
                polygons: [],
                userIsAdmin: false,
                bounds: [],
                legend: [{},{},{},{},{}],
                legendColorMap: {
                    12: ['#ffffff', '#fcf1db', '#fae8bf', '#f7de9e', '#f4ce58'],
                    13: ['#ffffff', '#ecded6', '#dfc4b6', '#d1a78e', '#ba6d1f'],
                    16: ['#ffffff', '#d7e2e8', '#b7cdd8', '#90b5c6', '#2989A7'],
                    17: ['#ffffff', '#e5f0d9', '#d2e5bb', '#bdd997', '#99c745'],
                },
                numberOfFieldAssignments: null,
                mapStyles: [
                    {
                        'featureType': 'landscape',
                        'stylers': [
                            {
                                'visibility': 'off'
                            }
                        ]
                    },
                    {
                        'featureType': 'poi',
                        'stylers': [
                            {
                                'visibility': 'off'
                            }
                        ]
                    },
                    {
                        'featureType': 'road',
                        'stylers': [
                            {
                                'visibility': 'off'
                            }
                        ]
                    },
                    {
                        'featureType': 'transit',
                        'stylers': [
                            {
                                'visibility': 'off'
                            }
                        ]
                    }
                ]
            }
        },
        computed: {
            statisticsOrdered() {
                let compare = (a, b) => {
                    if (a.propsData.SortOrder < b.propsData.SortOrder)
                        return -1;
                    if (a.propsData.SortOrder > b.propsData.SortOrder)
                        return 1;
                    return 0;
                };

                return this.statistics.sort(compare);
            }
        },
        methods: {
            getCropYearOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Dashboard/GetCropYears',
                }).then(response => {
                    this.cropYearOptions = response.CropYears.map(y => {
                        return {value: y.CropYear, text: y.Name}
                    });
                });
            },
            getCountyOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Dashboard/GetCounties',
                    params: {
                        IncludeAggregates: true
                    }
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = { text: type.DisplayText, value: type.CountyID };
                        this.countyOptions.push(dataObject);
                    });

                    setTimeout(() => {
                        this.setDefaultCountyOption();
                    });
                });
            },
            getTagOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Tags/GetAll'
                }).then(response => {
                    response.forEach(tag => {
                        let formattedOption = { text: tag.TagName, value: tag.TagID };
                        this.tagOptions.push(formattedOption);
                    });
                });
            },
            getMapStatisticOptions() {
                return DM.http({
                    method: 'GET',
                    url: '/Dashboard/GetAllHeatmapStatistics'
                }).then(response => {
                    response.forEach(stat => {
                        let formattedOption = { text: stat.StatisticName, value: stat.DashboardStatisticID };
                        this.mapStatisticOptions.push(formattedOption);
                    });

                    setTimeout(() => {
                        this.mapStatistic = this.mapStatisticOptions[0].value;
                    });
                });
            },
            getStarScoreChart() {
                DM.http({
                    method: 'POST',
                    url: '/Dashboard/GetStarScoreStatistics',
                    data: {
                        cropYear: this.cropYear || -1,
                        countyID: this.county || -1,
                        tagIDs: this.tags
                    }
                })
                    .then(response => {
                        let data = {
                            StatisticName: response.StatisticName,
                            SortOrder: 3,
                            SeriesData: [],
                            StarScoreStatisticsResponse: response
                        };

                        response.CropYearStarScores.forEach(s => {
                            let series = {
                                Year: s.CropYear,
                                OneStar: s.StarScores[0].NumberOfFields,
                                TwoStar: s.StarScores[1].NumberOfFields,
                                ThreeStar: s.StarScores[2].NumberOfFields,
                                FourStar: s.StarScores[3].NumberOfFields,
                                FiveStar: s.StarScores[4].NumberOfFields,
                            };

                            data.SeriesData.push(series);
                        });

                        this.statistics.push({
                            type: 'starScoresPerYearCardComponent',
                            propsData: data
                        });
                });
            },
            getSimpleStatistics() {
                DM.http({
                    method: 'POST',
                    url: '/Dashboard/GetSimpleStatistics',
                    data: {
                        cropYear: this.cropYear || -1,
                        countyID: this.county || -1,
                        tagIDs: this.tags
                    }
                })
                    .then(response => {
                        this.numberOfFieldAssignments = response.NumberOfFieldAssignments;
                        response.Statistics.forEach(s => {
                            s.StatisticValue = s.StatisticValue.replaceAll('\n', '<br />');
                            if(s.StatisticName === 'Top Improvement Plan Practices') {
                                s.fontSize = '16px';
                            } else {
                                s.fontSize = '54px';
                            }
                            this.statistics.push({
                                type: 'simpleCardComponent',
                                propsData: s
                            });
                        });
                     });
            },
            getHeatMapMetrics() {
                DM.http({
                    method: 'POST',
                    url: '/Dashboard/GetHeatmapStatistics',
                    data: {
                        cropYear: this.cropYear || -1,
                        countyID: this.county || -1,
                        tagIDs: this.tags,
                        statisticID: this.mapStatistic
                    }
                })
                    .then(response => {
                        let values = response.map(c => c.StatisticValue);
                        let valueMax = Math.max(...values);
                        let legendMax = this.getLegendMax(valueMax);

                        this.polygons.forEach((p, index) => {
                            p.setMap(null);
                        });
                        this.polygons = [];

                        if(response.length === 0) {
                            return;
                        }

                        this.setLegendRanges(legendMax);
                        this.bounds = new window.google.maps.LatLngBounds();

                        response.forEach((c, index) => {
                            let p = this.counties[c.CountyID];
                            let geoJson = JSON.parse(p.CountyBoundaryGeoJson).geometry;
                            let outerBox = this.getOuterBox(geoJson.coordinates);
                            let color = this.setPolygonColor(c.StatisticValue, legendMax);
                            let fieldOverlay = new google.maps.Polygon({
                                paths: outerBox,
                                geodesic: true,
                                strokeColor: color,
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: color,
                                fillOpacity: 0.5,
                            });

                            this.polygons.push(fieldOverlay);

                            let bounds = new google.maps.LatLngBounds();
                            outerBox.forEach(polygon => {
                                polygon.forEach(coordinate => {
                                    bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
                                });
                            });

                            let txt = new window.FieldLabelOverlay.overlay(
                                this.map,
                                bounds.getCenter(),
                                p.CountyName + ' County - ' + c.StatisticValue.toLocaleString() ,
                                false
                            );

                            google.maps.event.addListener(fieldOverlay, 'mouseover', () => {
                                txt.show();
                                let div = document.createElement('DIV');
                                div.classList.add('app-legend-tick');
                                let container = document.getElementById('app-legend-container');
                                div.style.left = this.getLegendTickLeftPosition(c.StatisticValue, legendMax);
                                container.append(div);
                            });

                            google.maps.event.addListener(fieldOverlay, 'mouseout', function() {
                                txt.hide();
                                document.getElementsByClassName('app-legend-tick')[0].remove();
                            });

                            fieldOverlay.setMap(this.map);
                        });

                        this.map.fitBounds(this.bounds);
                    });
            },
            getLegendMax(valueMax) {
                if(valueMax <= 10) {
                    return valueMax;
                } else if(valueMax <= 50) {
                    return Math.ceil(valueMax / 5) * 5;
                } else if(valueMax <= 100) {
                    return Math.ceil(valueMax / 10) * 10;
                } else if(valueMax <= 1000) {
                    return Math.ceil(valueMax / 100) * 100;
                } else if(valueMax <= 10000) {
                    return Math.ceil(valueMax / 1000) * 1000;
                } else if(valueMax <= 100000) {
                    return Math.ceil(valueMax / 5000) * 5000;
                } else if(valueMax <= 1000000) {
                    return Math.ceil(valueMax / 10000) * 10000;
                } else if(valueMax <= 100000000) {
                    return Math.ceil(valueMax / 100000) * 100000;
                } else {
                    return Math.ceil(valueMax / 1000000) * 1000000;
                }
            },
            setLegendRanges(legendMax) {
                let oneFifth = legendMax/5;
                this.legend[0].range = '0 - ' + oneFifth.toLocaleString();
                this.legend[1].range = oneFifth.toLocaleString() + ' - ' + (oneFifth*2).toLocaleString();
                this.legend[2].range = (oneFifth*2).toLocaleString() + ' - ' + (oneFifth*3).toLocaleString();
                this.legend[3].range = (oneFifth*3).toLocaleString() + ' - ' + (oneFifth*4).toLocaleString();
                this.legend[4].range = (oneFifth*4).toLocaleString() + ' - ' + legendMax.toLocaleString();

                if(this.legendColorMap[this.mapStatistic]) {
                    this.legend[0].color = this.legendColorMap[this.mapStatistic][0];
                    this.legend[1].color = this.legendColorMap[this.mapStatistic][1];
                    this.legend[2].color = this.legendColorMap[this.mapStatistic][2];
                    this.legend[3].color = this.legendColorMap[this.mapStatistic][3];
                    this.legend[4].color = this.legendColorMap[this.mapStatistic][4];
                } else {
                    // Give default yellow just in case so it doesn't look broken
                    this.legend[0].color = this.legendColorMap[12][0];
                    this.legend[1].color = this.legendColorMap[12][1];
                    this.legend[2].color = this.legendColorMap[12][2];
                    this.legend[3].color = this.legendColorMap[12][3];
                    this.legend[4].color = this.legendColorMap[12][4];
                }
            },
            setPolygonColor(statisticValue, legendMax) {
                let percentage = statisticValue/legendMax*100;
                if(statisticValue === 0 || (percentage >= 0 && percentage <= 20)) {
                    return this.legendColorMap[this.mapStatistic][0];
                } else if(percentage > 20 && percentage <= 40) {
                    return this.legendColorMap[this.mapStatistic][1];
                } else if(percentage > 40 && percentage <= 60) {
                    return this.legendColorMap[this.mapStatistic][2];
                } else if(percentage > 60 && percentage <= 80) {
                    return this.legendColorMap[this.mapStatistic][3];
                } else if(percentage > 80 && percentage <= 100) {
                    return this.legendColorMap[this.mapStatistic][4];
                }
            },
            getOuterBox(coordinates) {
                let outerBox = []
                coordinates.forEach((polygon) => {
                    let fieldBoundary = [];
                    let outerBounds = polygon[0];
                    outerBounds.forEach((coordinate) => {
                        let latLongObj = {lat: coordinate[1], lng: coordinate[0]};
                        this.bounds.extend(latLongObj);
                        fieldBoundary.push(latLongObj);
                    });

                    outerBox.push(fieldBoundary);
                });
                return outerBox;
            },
            getLegendTickLeftPosition(statisticValue, legendMax) {
                let percentage = statisticValue/legendMax*100;
                if(percentage >= 0 && percentage < 20) {
                    return '10%';
                } else if(percentage >= 20 && percentage < 40) {
                    return '30%';
                } else if(percentage >= 40 && percentage < 60) {
                    return '50%';
                } else if(percentage >= 60 && percentage < 80) {
                    return '70%';
                } else if(percentage >= 80 && percentage < 100) {
                    return '90%';
                }
            },
            getHeatMapPolygons() {
                DM.http({
                    method: 'GET',
                    url: '/Dashboard/GetCountyBoundaries'
                })
                    .then(response => {
                        response.forEach(p => {
                            this.counties[p.CountyID] = p;
                        });
                    });
            },
            setDefaultCountyOption() {
                let countyIDs = app.signedInUser.CountyIDs;
                let defaultCountyOption = -3;
                if (app.signedInUser.UserType === 'Admin' && countyIDs.length === 0) {
                    this.county = defaultCountyOption;
                } else {
                    let filteredCounties  = [];
                    this.countyOptions.forEach(o => {
                        if (countyIDs.includes(o.value)) {
                            filteredCounties.push(o);
                        }
                    });

                    if (app.signedInUser.UserType === 'Admin' && filteredCounties.length === 0) {
                        this.county = defaultCountyOption;
                    } else {
                        let sortedCounties = filteredCounties.sort((c) => c.CountyName);
                        let firstCounty = sortedCounties[0];
                        this.county = firstCounty.value;
                    }
                }
            },
            tabSelecting(data) {
                this.activeTabIndex = data.selectingIndex;
            },
            getStatistics() {
                if(this.activeTabIndex === 0) {
                    this.statistics = [];
                    this.getSimpleStatistics();
                    this.getStarScoreChart();
                } else {
                    this.getHeatMapMetrics();
                }
            }
        },
        created() {
            this.userIsAdmin = app.signedInUser.UserType === 'Admin';

            this.getCropYearOptions()
                .then(this.getCountyOptions)
                .then(this.getTagOptions)
                .then(this.getMapStatisticOptions)
                .then(response => {
                    this.getStatistics();
                    this.getHeatMapPolygons();
                });

            // console.log(app.shadeColor(.3, '#99c745'));
            // console.log(app.shadeColor(.5, '#99c745'));
            // console.log(app.shadeColor(.7, '#99c745'));
        },
        mounted() {
            let initMap = () => {
                this.map = new google.maps.Map(document.getElementById('map-container'), {
                    center: { lat: 40.454769, lng: -86.915703 },
                    zoom: 8,
                    disableDefaultUI: true,
                    zoomControl: true,
                    mapTypeId: 'hybrid'
                });

                this.map.set('styles', this.mapStyles);

                window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
                    this.getHeatMapMetrics();
                });
            };

            if(this.$store.state.googleMapsIsLoaded) {
                initMap();
            } else {
                this.$watch('$store.state.googleMapsIsLoaded', (newVal) => {
                    if(newVal) {
                        initMap();
                    }
                });
            }
        }
    }
</script>
